// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/pie
import React, { Component } from 'react';
import { ResponsivePie } from '@nivo/pie'
import "./App.css";
import dataPie from './2023_PieData.json';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.




class Pie23 extends Component {

  render() {
    const MyResponsivePie = ({ data /* see data tab */ }) => (
      <ResponsivePie data={dataPie} 
            
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
              from: 'color',
              modifiers: [
                  [
                      'darker',
                      0.2
                  ]
              ]
          }}
          enableArcLinkLabels={false}
          defs={[
              {
                  id: 'dots',
                  type: 'patternDots',
                  background: 'inherit',
                  color: 'rgba(255, 255, 255, 0.3)',
                  size: 4,
                  padding: 1,
                  stagger: true
              },
              {
                  id: 'lines',
                  type: 'patternLines',
                  background: 'inherit',
                  color: 'rgba(255, 255, 255, 0.3)',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
              }
          ]}
          colors={{ datum: 'data.color' }}
          legends={[
              {
                  anchor: 'bottom',
                  direction: 'column',
                  justify: false,
                  translateX: -5,
                  translateY: 80,
                  itemsSpacing: 7,
                  itemWidth: 50,
                  itemHeight: 10,
                  itemTextColor: '#999',
                  itemDirection: 'left-to-right',
                  itemOpacity: 1,
                  symbolSize: 10,
                  symbolShape: 'square',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemTextColor: '#000'
                          }
                      }
                  ]
              }
          ]}
      />
  )
    
  
  

    return (
      <div className='pie'> 
      <MyResponsivePie />
     
      </div>
    
      
    );
  }
  }
      

export default Pie23;