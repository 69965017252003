import React, { Component, useState, memo } from "react";

import "./App.css";
import dataCal23 from './2023_MTA.json';
//import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import ReactTooltip from "react-tooltip";
//import {Tweet} from 'react-twitter-widgets';

// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/calendar
import { ResponsiveCalendar, ResponsiveCalendarCanvas } from '@nivo/calendar'
/*import {calendarDefaultProps} from '@nivo/calendar'
import { isVisible } from "@testing-library/user-event/dist/utils";
import { generateDayCounts } from '@nivo/generators'
import string from "calendar-graph/lib/render/string";*/


// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.















   
class aCalendar extends Component {

  
 

   
  render() {

  

  // make sure parent container have a defined height when using
  // responsive component, otherwise height will be 0 and
  // no chart will be rendered.
  // website examples showcase many properties,
  // you'll often use just a few of them.
  const MyResponsiveCalendar = ({ data /* see data tab */ }) => (
      <ResponsiveCalendar
      data={dataCal23}
      
        /*the color boundary is 159, there must be a larger value to preserve the scaling*/
          from="2023-01-02"
          to="2023-12-31"
          emptyColor="#eeeeee"
          colors={[ '#3E505B', '#3E505B', '#3E505B','#3E505B', '#9bbce3' ]}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          monthSpacing={10} 
          isInteractive="false"
          tooltip={() => (<></>)}
        // onClick={(data) => this.hideComponent(data)}
          /*onMouseEnter={(data) => this.hideComponent(data)}
          
          /*tooltip={() => (<></>)}
          /*this works great! >>>>> onClick={() => this.hideComponent("showHideCal")}
          
         /*onClick={(data) => {babyPrinter(data.value)}}
          onClick={(data) => {Example(Number(data.value))}}
          onClick={(input) => {
            return(
            <div>
              {Example(Number(input))}
            </div>
            
            )}}*/
         /*tooltipFormat={value => Number(value).toLocaleString('en-US', {minimumFractionDigits: 2}) + '$'}*/
   
            /*
            return (
            <div style = {{
              position: 'absolute',}}>
              {mouseoverDecoder(input.value)}
            </div>
          )}} */
          yearSpacing={40}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
              {
                  anchor: 'bottom-right',
                  direction: 'row',
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: 'right-to-left'
              }
          
          ]}
      />
  )
  

    return (
      <div className="App">


        

       
        
        <div className="map">
         <MyResponsiveCalendar/>
      
        
        </div>
        <div className="caption">
      
       
        </div>
        
        <div>
        </div>
    
       
      </div>
      
    );
  }
}

export default aCalendar;
