import React, { Component } from 'react';

import './App.css';
import Pdf from "./resume/evanCookResume_24.01.24.pdf";
import usaLogo from "./usa829.svg"
import logo635 from "./iatse635.svg"


import 'react-calendar-heatmap/dist/styles.css';

import Pie from './PieChart';
import Pie23 from './PieChart23';
import Cal2 from './Calendar_n copy';
import MTACal23 from './2023MTACal';
import WorkCal23 from './2023WorkCal';




const Piestyles22 = {
  root: {
    fontFamily: "ubuntu, sans-serif",
   
    position: "relative",
    width: 600,
    height: 600
  },
  overlay: {
    position: "absolute",
    top: 290,
    right: 180,
    bottom: 0,
    left: -30,
    display: "flex",
    flexDirection: "column",
    
    justifyContent: "center",
    fontSize: 10,
    color: "black",
    textAlign: "center",
    // This is important to preserve the chart interactivity
    pointerEvents: "none"
  },
  totalLabel: {
    fontSize: 10
  }
};

const Piestyles23 = {
  root: {
    fontFamily: "ubuntu, sans-serif",
   
    position: "relative",
    width: 600,
    height: 600
  },
  overlay: {
    position: "relative",

    display: "inline-block",
top: -155,
left: 60,
    justifyContent: "center",
    textAlign: "center",
    fontSize: 10,
    color: "black",

    // This is important to preserve the chart interactivity
    pointerEvents: "none"
  },
  totalLabel: {
    fontSize: 10
  }
};



class App extends Component {

  render() {
  
    return (
  

<div className="App">

  
    
    

<div class='wrapper'>
  <div class='row'>
    <div class='column'>
      
    <aside>
    
     <div className="home">
      
        <h1>evan cook</h1>
        <div className="subheader">is a sound designer.</div> <br/>
        <div>
        <p>evan does sound design for theater,<br/> live performance, and the internet.</p>
        
        <p>evan is also known for his <a href="https://evancook.gumroad.com/l/landline" target = "_blank" rel="noopener noreferrer">phones,</a> <br/><a href="https://www.instagram.com/p/CafzwboPlqb/" target = "_blank" rel="noopener noreferrer">custom </a> <a href="https://github.com/evanmcook/ac22" target = "_blank " rel="noopener noreferrer"> keyboards, </a> <a href="https://evancook.gumroad.com/l/sinkcookbook" target = "_blank" rel="noopener noreferrer">samples, </a><a href="https://github.com/evanmcook" target = "_blank" rel="noopener noreferrer">code,</a><br/> and the sound for <a href="https://imissmycafe.com" target = "_blank" rel="noopener noreferrer"> i miss my cafe.</a> </p>
     </div>

     </div>
    
      <div class="resumeLink">

        <div id= "content">   
          <div className = "resume">
        <a href = {Pdf} target = "_blank" rel="noopener noreferrer">you can download his resume here.</a>

         </div>
         <br/>
         <div className = "home">
        <p>in 2022 and 2023, evan primarily <br/> worked as an assistant sound designer.
        </p>

        <div> <img src={usaLogo} alt="IATSE USA829 Logo" width={100}/> <img className= "l635" alt="IATSE 635 Logo"src={logo635} width={55}/></div>
      

        </div>

         
        </div>
      </div>
   
    </aside>
      
    </div>

    <div class='column'>
      
      <div class ='row'>
          <div>
          

          </div>

    <div className='caler'>
     
     <WorkCal23 />
    
     </div>

     <div className='legend23'>
     <p>in 2023, evan primarily worked as an assistant sound designer. <br className='brnodisplay'/>
     he joined IATSE Local USA829, and was the associate sound designer <br className='brnodisplay'/>for 
     the Broadway production of <i>Just For Us </i> at The Hudson Theater.
        </p>
      
      <p>
        evan assisted or was the associate of sound designers <br className='brnodisplay'/> Rob Milburn, Michael Bodeen, Palmer Hefferan, and Brett Jarvis.
      </p>
        
    </div>

    <div className='pieBody'>
    <Pie23 />
        <div style={Piestyles23.overlay}>
          <span>2023</span>
        </div>
    </div>

    <div className='caler'>
     
      <MTACal23 />
      <div className='legend23'>
      <p> evan used the MTA on 201 of the 365 days of 2023, <br className='brnodisplay'/>with his longest streak of 9 days of use in a row.</p>
      </div>
      </div>

      <div className='caler'>
     
      <Cal2 />
      
     
      </div>
      <div className='legend23'><p>in 2022, evan assisted sound designers Lee Kinney, Daniel Kluger, <br className='brnodisplay'></br> Carsen Joenk, 
Palmer Hefferan, Tye Hunt Fitzgerald, Sinan Refik Zafar, <br className='brnodisplay'></br> Mikaal Sulaiman, Fan Zhang, and Jacqui Herter.<br br className='brnodisplay'></br></p></div>
      <div className='pieBody23'>
      <Pie />
         <div style={Piestyles23.overlay}>
      <span>2022</span>
     
          </div>
      </div>
      </div>

      <div class='green'>
      
      </div>
    </div>
    <div class = 'column'>
      
    <div class ='row'>
  
      </div>
    </div>
  </div>
</div>



  
</div>
    );
  
  } 
}
  

export default App;
