import React, { Component, useState, memo } from "react";

import "./App.css";
import dataCal from './2022DateData.json';
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import ReactTooltip from "react-tooltip";
import {Tweet} from 'react-twitter-widgets';

// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/calendar
import { ResponsiveCalendar, ResponsiveCalendarCanvas } from '@nivo/calendar'
/*import {calendarDefaultProps} from '@nivo/calendar'
import { isVisible } from "@testing-library/user-event/dist/utils";
import { generateDayCounts } from '@nivo/generators'
import string from "calendar-graph/lib/render/string";*/


// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.




const CustomTooltip = data => {

  var idx = mouseoverDecoder(data.value);
  if (data.value === undefined) return null
  return (
    
    <span style={{color:'black',backgroundColor:'#EEEEEE',borderRadius: 5 + 'px', padding: 3 + 'px'}}>
       {idx}
       <div className="tooltipA"></div>
    </span>
    
      
  )
}



function mouseoverDecoder(idx){
  var inp = Number(idx) 
  switch(inp){
  case  1:
      return(
        "Prayer for the French Republic"
      )
  
    case  2:
      return(
        "QLab scripting"
  
      )


    case  3:
      return(
       "Lizzie"
  
      )
  
    case  4:
      return(
        "Made By God"
  
      )

    case  5:
      return(
        "Help"
  
      )

    case  6:
      return(
        "Skin Of Our Teeth"
  
      )
  

    case  7:
      return(
        "The Vagrant Trilogy"
      )

    case  8:
      return(
        "Lessons In Survival: 1971"
  
      )

    case  9:
      return(
        "HAIR"
      )

      case  10:
      return(
        "Becky Nurse of Salem"
  
      )

      case  11:
      return(
        "You Will Get Sick"
  
      )

      case  12:
      return(
        "Evanston Salt Costs Climbing"
  
      )

      case  13:
      return(
        "Much Ado About Nothing"
  
      )
   
    case  14:
      return(
        "The Little Mermaid"
  
      )

      

    case 100:
      return(
        "Orioles data tracker"
      )

      case  101:
      return(
        "website coding"
  
      )

    case  159:
      return(
        "IATSE Stagehand"
  
      )

    case  160:
      return(
        "Stagehand"
  
      )
  

    case  161:
      return(
        "A1, Lessons in Survival: 1971"
  
      )

    case  162:
      return(
        "Soldering installation"
  
      )

    case  163:
      return(
        "Geoffrey Ballet School"
  
      )

    case  104:
      return(
        "Landline microphone system"
  
      )
      case  105:
      return(
        "sleeping dinosaur keyboard"
  
      )
  
   
      default :
  
      }
}

function Prayer(){
return(
  <div> 
    <p><b>Prayer for the French Republic</b>
    <br></br>
       @ Manhattan Theater Club
       <br></br>
       sound designers: Dan Kluger, Lee Kinney
       <br></br>
       assistant sound designer w/ associate Carsen Joenk</p>
    <p></p>
  </div>
)

}

function Sleeper(){
  return(
    <div> 
      <b><p>sleeping dinosaur keyboard commission</p></b>

      <Tweet tweetId="1498372331374362632" />
  
    
    </div>
  )
  
  }

  function Lizzie(){
    return(
      <div> 
        <p>
       <b>Lizzie: The Musical</b> 
       <br></br>
       @ Molloy College
       <br></br>
        sound designer Carsen Joenk
        <br></br>
        assistant sound designer
        </p>
      </div>
      
    )
    
    }

    function MBG(){
      return(
        <div> 
          <p>
         <b>Made By God</b> 
         <br></br>
         @ Irish Rep
         <br></br>
          sound designer Carsen Joenk
          <br></br>
          executed notes as assistant sound designer
          </p>
        </div>
        
      )
      
      }

    function Help(){
      return(
        <div> 
         <p><b>Help</b> 
         <br></br>
          @ The Shed
          <br></br>
          sound designer Lee Kinney
          <br></br>
          assistant sound designer alongside Tye Fitzgerald
          </p>
        </div>
        
      )
      
      }

      function Skin (){
        return(
          <div> 
         <p><b>The Skin Of Our Teeth</b> 
         <br></br>
          @ Lincoln Center, Vivian Beaumont Theater
          <br></br>
          sound designer Palmer Hefferan
          <br></br>
          associate sound designer Andrew Rovner
          <br></br>
          assistant sound designer
          </p>
        </div>
        )
      }

      function TVT (){
        return(
          <div> 
         <p><b>The Vagrant Trilogy</b> 
         <br></br>
          @ The Public, Luester Hall 
          <br></br>
          sound designers Tye Hunt Fitzgerald, Sinan Refik Zafar
          <br></br>
          assistant sound designer
          </p>
        </div>
        )
      }

      function LIS (){
        return(
          <div> 
         <p><b>Lessons In Survival: 1971</b> 
         <br></br>
          @ The Vineyard 
          <br></br>
          sound designer Lee Kinney
          <br></br>
          assistant sound designer, A1
          </p>
        </div>
        )
      }

      function Hair (){
        return(
          <div> 
         <p><b>HAIR</b> 
         <br></br>
          @ Weston Theater Company
          <br></br>
          sound designer Lee Kinney
          <br></br>
          associate sound designer
          </p>
        </div>
        )
      }

      function GBS (){
        return(
          <div> 
         <p><b>Geoffrey Ballet School </b> 
         <br></br>
          @ Symphony Space
          <br></br>
          A1
          </p>
        </div>
        )
      }

      function Becky (){
        return(
          <div> 
         <p><b>Becky Nurse of Salem </b> 
         <br></br>
          @ Lincoln Center, Mitzi Newhouse
          <br></br>
          sound designer Palmer Hefferan
          <br></br>
          assistant sound designer w/ Bailey Trierweiler
          </p>
        </div>
        )
      }

      function YWGS (){
        return(
          <div> 
         <p><b>You Will Get Sick </b> 
         <br></br>
          @ Roundabout Theatre Company, Laura Pels
          <br></br>
          sound designer Lee Kinney
          <br></br>
          assistant sound designer w/ Carsen Joenk
          </p>
        </div>
        )
      }

      function ESCC (){
        return(
          <div> 
         <p><b>Evanston Salt Costs Climbing </b> 
         <br></br>
          @ Signature Theater, Griffin Theater, w/ The New group
          <br></br>
          sound designer Mikaal Sulaiman 
          <br></br>
          assistant sound designer + additional sound design
          </p>
        </div>
        )
      }

      function Much (){
        return(
          <div> 
         <p><b>Much Ado About Nothing </b> 
         <br></br>
          @ Shakespeare Theater Company
          <br></br>
          sound designer Fan Zhang 
          <br></br>
          assistant sound designer
          </p>
        </div>
        )
      }
      
      function Merm (){
        return(
          <div> 
         <p><b>The Little Mermaid </b> 
         <br></br>
          @ Syracuse Stage
          <br></br>
          sound designer Jacqui Herter
          <br></br>
          assistant sound designer
          </p>
        </div>
        )
      }

      function Solder(){
        return(
          <div> 
         <p>soldering for company XIV, Bad Monkey Props, Signature Theater.
         <br></br>
         evan fabricates pcbs, cable, custom LED tape assemblies, custom keyboards, and audio equipment.
         <br></br>
         he is also experienced in component salvage from PCBs.
          </p>
        </div>
        )
      }


      function Landline(){
        return(
          <div> 
         <p><b>Landline Microphone System </b> 
         <br></br>
         <a href="https://evancook.gumroad.com/l/landline" target="_blank">the Landline system </a> allows users to choose between a phantom-powered carbon button microphone and a dynamic microphone.
         re-amp in the studio, get some crunch on your vocals, gossip about your favorite DAW in the late night voice chat.
        
          </p>
        </div>
        )
      }







   
class aCalendar extends Component {

  
 
  constructor() {

    super();

    this.state = {

      showHideCal: true,

      showHidePrayer: false,
      
      showHideSleeper: false,

      showHideLizzie: false,

      sHMBG: false,

      sHHelp: false,

      sHSkin: false,

      sHTVT: false,

      sHLIS: false,

      sHHair: false,

      sHBecky: false,

      sHYWGS: false,

      sHESCC: false, 

      sHMuch: false,

      sHMerm: false,

      sHLIS: false,

      sHSolder: false,

      sHGeof: false,

      sHLandline: false,

    };

    this.hideComponent = this.hideComponent.bind(this);

  }

  setClear(){
    this.setState ({
     
      showHideCal: true,

        showHidePrayer: false,
        
        showHideSleeper: false,
    
        showHideLizzie: false,
    
        sHMBG: false,
    
        sHHelp: false,
    
        sHSkin: false,
        
        sHMBG: false,
  
        sHSkin: false,
  
        sHTVT: false,
  
        sHLIS: false,
  
        sHHair: false,
  
        sHBecky: false,
  
        sHYWGS: false,
  
        sHESCC: false, 
  
        sHMuch: false,
  
        sHMerm: false,
  
        sHLIS: false,
  
        sHSolder: false,
  
        sHGeof: false,
  
        sHLandline: false
    
      });
    }
  

  hideComponent(name) {


    var passer = name.value;
  
    switch (passer) {
  
      case 0:
        
        this.setState({ showHideCal: !this.state.showHideCal });
  
        break;

        case 1:
          this.setClear();
          this.setState({ showHidePrayer: !this.state.showHidePrayer });
    
          break;   
  
      case 105:
        this.setClear();
        this.setState({ showHideSleeper: !this.state.showHideSleeper });
  
        break;
      
      case 3:
        this.setClear();
          this.setState({ showHideLizzie: !this.state.showHideLizzie });
    
          break;

      case  4:
        this.setClear();
            this.setState({ sHMBG: !this.state.sHMBG });
      
         

        break;
      case  5:
         
        this.setClear();
            this.setState({ sHHelp: !this.state.sHHelp});
         
        break;

      case  6:
        this.setClear();
          this.setState({ sHSkin: !this.state.sHSkin });
   
      
        
        break;
    
      case  7:
        this.setClear();
          this.setState({ sHTVT: !this.state.sHTVT });
           
       
        break;
        case  8:
          this.setClear();
          this.setState({ sHLIS: !this.state.sHLIS });
      

        break;
        case  9:
          this.setClear();
          this.setState({ sHHair: !this.state.sHHair });
        
      
          
        break;
       
        case 10:
          this.setClear();
          this.setState({ sHBecky: !this.state.sHBecky });
          
          break;
          
        case  11:
          this.setClear();
          this.setState({ sHYWGS: !this.state.sHYWGS });
      
          break;
        case  12:
          this.setClear();
          this.setState({ sHESCC: !this.state.sHESCC });
      
          break;
        case  13:
          this.setClear();
          this.setState({ sHMuch: !this.state.sHMuch });
      
          
        break;
        case  14:
          this.setClear();
            this.setState({ sHMerm: !this.state.sHMerm });
      
          
        break;

    
        case 161:
          this.setClear();
        this.setState({ sHLIS: !this.state.sHLIS });
      
        
        break;
        case  162:
          this.setClear();
          this.setState({ sHSolder: !this.state.sHSolder });
      
          
        break;
        case  163:
          this.setClear();
          this.setState({ sHGeof: !this.state.sHGeof });
      
       
        break;
        case  104:
          this.setClear();
          this.setState({ sHLandline: !this.state.sHLandline });
      
         
        break;


        case 500:
          this.setState({ showHideText: !this.state.showHideText });
         
        break;
  
      default:
  
     
  
    }

  }

  dayTotal(data){
     var len = Object.keys(data).length;
      return len;
      
  }
  

  displayEvent(any){
    alert(any);
  }

  displayShow(nameShow){
    switch(nameShow){
      case "Prayer":
        this.setState({showHidePrayer: !this.state.showHidePrayer})
      break;

      default:
    }
  }
 

   
  render() {
    const { showHideText, showHideCal, showHideSleeper, showHidePrayer,showHideLizzie, sHMBG,
      sHHelp, sHSkin,sHTVT, sHLIS, sHHair, sHBecky, sHYWGS, sHESCC, sHMuch, sHMerm, sHSolder, sHGeof, sHLandline} = this.state;
    

    const MyResponsiveCalendar = ({data}) => (
  
      <ResponsiveCalendar class="res"
      data={dataCal}
      /*
          data={[
            {
              "value": 159,
              "day": "2022-01-02",
            },
            {
              "value": 159,
              "day": "2022-01-03",
  
            },
            {
              "value": 159,
              "day": "2022-01-04",
            },
            {
              "value": 159,
              "day": "2022-01-05",
            },
            {
              "value": 159,
              "day": "2022-01-06",
            },
            {
              "value": 159,
              "day": "2022-01-07",
            },
            {
              "value": 159,
              "day": "2022-01-08",
            },
            {
              "value": 1,
              "day": "2022-01-10",
            },
            {
              "value": 1,
              "day": "2022-01-11",
            },
            {
              "value": 1,
              "day": "2022-01-12",
            },
            {
              "value": 1,
              "day": "2022-01-13",
            },
            {
              "value": 1,
              "day": "2022-01-14",
            },
            {
              "value": 1,
              "day": "2022-01-15",
            },
            {
              "value": 1,
              "day": "2022-01-16",
            },
            {
              "value": 159,
              "day": "2022-01-18",
            },
            {
              "value": 1,
              "day": "2022-01-19",
            },
            {
              "value": 1,
              "day": "2022-01-20",
            },
            {
              "value": 1,
              "day": "2022-01-21",
            },
            {
              "value": 1,
              "day": "2022-01-22",
            },
            {
              "value": 1,
              "day": "2022-01-23",
            },
            {
              "value": 1,
              "day": "2022-01-25",
            },
            {
              "value": 105,
              "day": "2022-01-26",
            },
            {
              "value": 1,
              "day": "2022-01-27",
            },
            {
              "value": 105,
              "day": "2022-01-28",
            },
            {
              "value": 105,
              "day": "2022-01-29",
            },
            {
              "value": 105,
              "day": "2022-02-02",
            },
            {
              "value": 105,
              "day": "2022-02-03",
            },
            {
              "value": 105,
              "day": "2022-02-04",
            },
            {
              "value": 105,
              "day": "2022-02-05",
            },
            {
              "value": 105,
              "day": "2022-02-06",
            },
            {
              "value": 3,
              "day": "2022-02-07",
            },
            {
              "value": 3,
              "day": "2022-02-08",
            },
            {
              "value": 3,
              "day": "2022-02-10",
            },
            {
              "value": 105,
              "day": "2022-02-11",
            },
            {
              "value": 4,
              "day": "2022-02-13",
            },
            {
              "value": 160,
              "day": "2022-02-15",
            },
            {
              "value": 4,
              "day": "2022-02-16",
            },
            {
              "value": 3,
              "day": "2022-02-18",
            },

            {
              "value": 3,
              "day": "2022-02-19",
            },
            {
              "value": 105,
              "day": "2022-02-21",
            },
            {
              "value": 105,
              "day": "2022-02-22",
            },
            {
              "value": 159,
              "day": "2022-02-23",
            },
            {
              "value": 3,
              "day": "2022-02-24",
            },
            {
              "value": 3,
              "day": "2022-02-25",
            },
            {
              "value": 160,
              "day": "2022-03-03",
            },
            {
              "value": 160,
              "day": "2022-03-04",
            },
            {
              "value": 160,
              "day": "2022-03-06",
            },
            {
              "value": 5,
              "day": "2022-03-08",
            },
            {
              "value": 5,
              "day": "2022-03-09",
            },
            {
              "value": 5,
              "day": "2022-03-10",
            },
            {
              "value": 5,
              "day": "2022-03-11",
            },
            {
              "value": 5,
              "day": "2022-03-12",
            },
            {
              "value": 5,
              "day": "2022-03-13",
            },
            {
              "value": 5,
              "day": "2022-03-15",
            },
            {
              "value": 5,
              "day": "2022-03-16",
            },
            {
              "value": 5,
              "day": "2022-03-17",
            },
            {
              "value": 5,
              "day": "2022-03-18",
            },
            {
              "value": 2,
              "day": "2022-03-20",
            },
            {
              "value": 6,
              "day": "2022-03-23",
            },
            {
              "value": 7,
              "day": "2022-03-24",
            },
            {
              "value": 6,
              "day": "2022-03-25",
            },
            {
              "value": 6,
              "day": "2022-03-26",
            },
            {
              "value": 6,
              "day": "2022-03-27",
            },
            {
              "value": 7,
              "day": "2022-03-29",
            },
            {
              "value": 7,
              "day": "2022-03-31",
            },
            {
              "value": 7,
              "day": "2022-04-01",
            },
            {
              "value": 7,
              "day": "2022-04-02",
            },
            {
              "value": 7,
              "day": "2022-04-03",
            },
            {
              "value": 7,
              "day": "2022-04-05",
            },
            {
              "value": 7,
              "day": "2022-04-06",
            },
            {
              "value": 7,
              "day": "2022-04-07",
            },
            {
              "value": 7,
              "day": "2022-04-08",
            },
            {
              "value": 7,
              "day": "2022-04-09",
            },
            {
              "value": 7,
              "day": "2022-04-12",
            },
            {
              "value": 7,
              "day": "2022-04-13",
            },
            {
              "value": 7,
              "day": "2022-04-14",
            },
            {
              "value": 7,
              "day": "2022-04-15",
            },
            {
              "value": 7,
              "day": "2022-04-16",
            },
            {
              "value": 100,
              "day": "2022-04-19",
            },
            {
              "value": 7,
              "day": "2022-04-20",
            },
            {
              "value": 100,
              "day": "2022-04-21",
            },
            {
              "value": 100,
              "day": "2022-04-22",
            },
            {
              "value": 100,
              "day": "2022-04-23",
            },
            {
              "value": 159,
              "day": "2022-04-26",
            },
            {
              "value": 159,
              "day": "2022-04-27",
            },
            {
              "value": 159,
              "day": "2022-04-28",
            },
            {
              "value": 8,
              "day": "2022-05-03",
            },
            {
              "value": 8,
              "day": "2022-05-04",
            },
            {
              "value": 8,
              "day": "2022-05-05",
            },
            {
              "value": 8,
              "day": "2022-05-06",
            },
            {
              "value": 8,
              "day": "2022-05-07",
            },
            {
              "value": 8,
              "day": "2022-05-08",
            },
            {
              "value": 8,
              "day": "2022-05-10",
            },
            {
              "value": 8,
              "day": "2022-05-11",
            },
            {
              "value": 8,
              "day": "2022-05-12",
            },
            {
              "value": 8,
              "day": "2022-05-13",
            },
            {
              "value": 8,
              "day": "2022-05-14",
            },
            {
              "value": 8,
              "day": "2022-05-15",
            },
            {
              "value": 8,
              "day": "2022-05-17",
            },
            {
              "value": 161,
              "day": "2022-05-18",
            },
            {
              "value": 161,
              "day": "2022-05-19",
            },
            {
              "value": 161,
              "day": "2022-05-20",
            },
            {
              "value": 161,
              "day": "2022-05-21",
            },
            {
              "value": 161,
              "day": "2022-05-22",
            },
            {
              "value": 161,
              "day": "2022-05-24",
            },
            {
              "value": 161,
              "day": "2022-05-25",
            },
            {
              "value": 161,
              "day": "2022-05-26",
            },
            {
              "value": 161,
              "day": "2022-05-27",
            },
            {
              "value": 161,
              "day": "2022-05-28",
            },
            {
              "value": 161,
              "day": "2022-05-29",
            },
            {
              "value": 161,
              "day": "2022-05-31",
            },
            {
              "value": 161,
              "day": "2022-06-01",
            },
            {
              "value": 161,
              "day": "2022-06-02",
            },
            {
              "value": 161,
              "day": "2022-06-03",
            },
            {
              "value": 161,
              "day": "2022-06-04",
            },
            {
              "value": 161,
              "day": "2022-06-05",
            },
            {
              "value": 161,
              "day": "2022-06-07",
            },
            {
              "value": 161,
              "day": "2022-06-08",
            },
            {
              "value": 161,
              "day": "2022-06-14",
            },
            {
              "value": 161,
              "day": "2022-06-15",
            },
            {
              "value": 161,
              "day": "2022-06-16",
            },
            {
              "value": 161,
              "day": "2022-06-17",
            },
            {
              "value": 161,
              "day": "2022-06-18",
            },
            {
              "value": 161,
              "day": "2022-06-19",
            },
            {
              "value": 161,
              "day": "2022-06-21",
            },
            {
              "value": 161,
              "day": "2022-06-22",
            },
            {
              "value": 161,
              "day": "2022-06-23",
            },
            {
              "value": 161,
              "day": "2022-06-24",
            },
            {
              "value": 161,
              "day": "2022-06-25",
            },
            {
              "value": 161,
              "day": "2022-06-26",
            },
            {
              "value": 161,
              "day": "2022-06-27",
            },
            {
              "value": 161,
              "day": "2022-06-28",
            },
            {
              "value": 161,
              "day": "2022-06-29",
            },
            {
              "value": 161,
              "day": "2022-06-30",
            },
            {
              "value": 162,
              "day": "2022-07-02",
            },
            {
              "value": 162,
              "day": "2022-07-03",
            },
            {
              "value": 162,
              "day": "2022-07-04",
            },
            {
              "value": 162,
              "day": "2022-07-05",
            },
            {
              "value": 162,
              "day": "2022-07-06",
            },
            {
              "value": 162,
              "day": "2022-07-08",
            },
            {
              "value": 162,
              "day": "2022-07-09",
            },
            {
              "value": 162,
              "day": "2022-07-10",
            },
            {
              "value": 162,
              "day": "2022-07-11",
            },
            {
              "value": 162,
              "day": "2022-07-12",
            },
            {
              "value": 9,
              "day": "2022-07-15",
            },
            {
              "value": 9,
              "day": "2022-07-16",
            },
            {
              "value": 9,
              "day": "2022-07-17",
            },
            {
              "value": 9,
              "day": "2022-07-19",
            },
            {
              "value": 163,
              "day": "2022-07-20",
            },
            {
              "value": 163,
              "day": "2022-07-21",
            },
            {
              "value": 163,
              "day": "2022-07-22",
            },
            {
              "value": 162,
              "day": "2022-07-25",
            },
            {
              "value": 162,
              "day": "2022-07-26",
            },
            {
              "value": 162,
              "day": "2022-07-29",
            },
            {
              "value": 162,
              "day": "2022-08-01",
            },
            {
              "value": 104,
              "day": "2022-08-03",
            },
            {
              "value": 104,
              "day": "2022-08-04",
            },
            {
              "value": 104,
              "day": "2022-08-07",
            },
            {
              "value": 163,
              "day": "2022-08-10",
            },
            {
              "value": 163,
              "day": "2022-08-11",
            },
            {
              "value": 163,
              "day": "2022-08-12",
            },
            {
              "value": 159,
              "day": "2022-08-16",
            },
            {
              "value": 159,
              "day": "2022-08-17",
            },
            {
              "value": 159,
              "day": "2022-08-18",
            },
            {
              "value": 159,
              "day": "2022-08-19",
            },
            {
              "value": 10,
              "day": "2022-08-21",
            },
            {
              "value": 11,
              "day": "2022-08-22",
            },
            {
              "value": 11,
              "day": "2022-08-23",
            },
            {
              "value": 11,
              "day": "2022-08-24",
            },
            {
              "value": 10,
              "day": "2022-08-25",
            },
            {
              "value": 10,
              "day": "2022-08-26",
            },
            {
              "value": 12,
              "day": "2022-08-29",
            },
            {
              "value": 11,
              "day": "2022-08-30",
            },
            {
              "value": 10,
              "day": "2022-08-31",
            },
            {
              "value": 10,
              "day": "2022-09-01",
            },
            {
              "value": 11,
              "day": "2022-09-02",
            },
            {
              "value": 12,
              "day": "2022-09-04",
            },
            {
              "value": 12,
              "day": "2022-09-05",
            },
            {
              "value": 162,
              "day": "2022-09-06",
            },
            {
              "value": 162,
              "day": "2022-09-07",
            },
            {
              "value": 12,
              "day": "2022-09-08",
            },
            {
              "value": 11,
              "day": "2022-09-09",
            },
            {
              "value": 12,
              "day": "2022-09-10",
            },
            {
              "value": 10,
              "day": "2022-09-11",
            },
            {
              "value": 11,
              "day": "2022-09-12",
            },
            {
              "value": 11,
              "day": "2022-09-13",
            },
            {
              "value": 11,
              "day": "2022-09-14",
            },
            {
              "value": 10,
              "day": "2022-09-15",
            },
            {
              "value": 12,
              "day": "2022-09-16",
            },
            {
              "value": 10,
              "day": "2022-09-17",
            },
            {
              "value": 10,
              "day": "2022-09-18",
            },
            {
              "value": 12,
              "day": "2022-09-19",
            },
            {
              "value": 10,
              "day": "2022-09-20",
            },
            {
              "value": 10,
              "day": "2022-09-21",
            },
            {
              "value": 10,
              "day": "2022-09-22",
            },
            {
              "value": 10,
              "day": "2022-09-23",
            },
            {
              "value": 11,
              "day": "2022-09-24",
            },
            {
              "value": 10,
              "day": "2022-09-25",
            },
            {
              "value": 10,
              "day": "2022-09-26",
            },
            {
              "value": 10,
              "day": "2022-09-27",
            },
            {
              "value": 10,
              "day": "2022-09-28",
            },
            {
              "value": 10,
              "day": "2022-09-29",
            },
            {
              "value": 10,
              "day": "2022-09-30",
            },
            {
              "value": 11,
              "day": "2022-10-01",
            },
           
            {
              "value": 10,
              "day": "2022-10-03",
            },
            {
              "value": 10,
              "day": "2022-10-04",
            },
            {
              "value": 10,
              "day": "2022-10-05",
            },
            {
              "value": 104,
              "day": "2022-10-06",
            },
            {
              "value": 104,
              "day": "2022-10-07",
            },
            {
              "value": 104,
              "day": "2022-10-08",
            },
            {
              "value": 104,
              "day": "2022-10-09",
            },
            {
              "value": 104,
              "day": "2022-10-10",
            },
            {
              "value": 104,
              "day": "2022-10-11",
            },
            {
              "value": 104,
              "day": "2022-10-12",
            },
            {
              "value": 104,
              "day": "2022-10-13",
            },
            {
              "value": 104,
              "day": "2022-10-14",
            },
            {
              "value": 12,
              "day": "2022-10-18",
            },
            {
              "value": 12,
              "day": "2022-10-19",
            },
            {
              "value": 12,
              "day": "2022-10-20",
            },
            {
              "value": 12,
              "day": "2022-10-21",
            },
            {
              "value": 12,
              "day": "2022-10-22",
            },
            {
              "value": 12,
              "day": "2022-10-23",
            },
            {
              "value": 12,
              "day": "2022-10-24",
            },
            {
              "value": 12,
              "day": "2022-10-25",
            },
            {
              "value": 12,
              "day": "2022-10-26",
            },
            {
              "value": 12,
              "day": "2022-10-27",
            },
            {
              "value": 12,
              "day": "2022-10-28",
            },
            {
              "value": 12,
              "day": "2022-10-29",
            },
            {
              "value": 12,
              "day": "2022-10-30",
            },
            {
              "value": 12,
              "day": "2022-10-31",
            },
            {
              "value": 12,
              "day": "2022-11-01",
            },
            {
              "value": 12,
              "day": "2022-11-02",
            },
            {
              "value": 12,
              "day": "2022-11-03",
            },
            {
              "value": 12,
              "day": "2022-11-04",
            },
            {
              "value": 104,
              "day": "2022-11-05",
            },
            {
              "value": 104,
              "day": "2022-11-09",
            },
            {
              "value": 104,
              "day": "2022-11-10",
            },
            {
              "value": 13,
              "day": "2022-11-12",
            },
            {
              "value": 13,
              "day": "2022-11-13",
            },
            {
              "value": 13,
              "day": "2022-11-14",
            },
            {
              "value": 13,
              "day": "2022-11-15",
            },
            {
              "value": 14,
              "day": "2022-11-19",
            },
            {
              "value": 14,
              "day": "2022-11-20",
            },
            {
              "value": 14,
              "day": "2022-11-21",
            },
            {
              "value": 14,
              "day": "2022-11-22",
            },
            {
              "value": 14,
              "day": "2022-11-23",
            },
            {
              "value": 14,
              "day": "2022-11-25",
            },
            {
              "value": 14,
              "day": "2022-11-26",
            },
            {
              "value": 101,
              "day": "2022-11-30",
            },
            {
              "value": 101,
              "day": "2022-12-01",
            },
            {
              "value": 101,
              "day": "2022-12-02",
            },
            {
              "value": 101,
              "day": "2022-12-03",
            },
            {
              "value": 2,
              "day": "2022-12-04",
            },
            {
              "value": 101,
              "day": "2022-12-05",
            },
            {
              "value": 101,
              "day": "2022-12-06",
            },
            {
              "value": 162,
              "day": "2022-12-07",
            },
            {
              "value": 162,
              "day": "2022-12-08",
            },
            {
              "value": 101,
              "day": "2022-12-09",
            },
            
          ]}*/
        /*the color boundary is 159, there must be a larger value to preserve the scaling*/
          from="2022-01-02"
          to="2022-12-31"
          emptyColor="#eeeeee"
          colors={[ '#9be3bf', '#9be3bf', '#9be3bf','#DCC7BE', '#36bf7a' ]}
          margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          monthSpacing={10} 
          isInteractive="true"
          //uncomment this next line to return to mouseclick activity
         //onClick={(data) => this.hideComponent(data)}

          /*onMouseEnter={(data) => this.hideComponent(data)}
          
          /*tooltip={() => (<></>)}
          /*this works great! >>>>> onClick={() => this.hideComponent("showHideCal")}
          
         /*onClick={(data) => {babyPrinter(data.value)}}
          onClick={(data) => {Example(Number(data.value))}}
          onClick={(input) => {
            return(
            <div>
              {Example(Number(input))}
            </div>
            
            )}}*/
         /*tooltipFormat={value => Number(value).toLocaleString('en-US', {minimumFractionDigits: 2}) + '$'}*/
       tooltip={(input) => {
            console.log(input.point)
            return(
              CustomTooltip(input)
            )
          }}
            /*
            return (
            <div style = {{
              position: 'absolute',}}>
              {mouseoverDecoder(input.value)}
            </div>
          )}} */
          yearSpacing={40}
          monthBorderColor="#ffffff"
          dayBorderWidth={2}
          dayBorderColor="#ffffff"
          legends={[
              {
                  anchor: 'bottom-right',
                  direction: 'row',
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: 'right-to-left'
              }
          
          ]}
          
      />
    )
  

    return (
      <div className="App">


        

       
        
        <div className="map">
         {showHideCal && (<MyResponsiveCalendar/>)}
  
        
        </div>
       <div className="caption">
         {showHidePrayer  && (<Prayer />)}
         {showHideSleeper && (<Sleeper />)}
         {showHideLizzie  && (<Lizzie />)}
         {sHHelp  && (<Help />)}
         {sHMBG && <MBG />}
         {sHSkin && (<Skin />)}
         {sHTVT && (<TVT />)}
         {sHLIS && (<LIS />)}
         {sHHair && (<Hair />)}
         {sHGeof && (<GBS />)}
         {sHBecky && (<Becky />)}
         {sHYWGS && (<YWGS />)}
         {sHESCC && (<ESCC />)}
         {sHMuch && (<Much />)}
         {sHMerm && (<Merm />)}
         {sHSolder && (<Solder />)}
         {sHLandline && (<Landline />)}
         
       
        </div>
        
        <div>
        </div>
    
       
      </div>
      
    );
  }
}

export default aCalendar;
